<template>
  <div class="agreement-category-fields">
    <a-spin :spinning="spinning">
      <!-- <page-header :title="l('Roles')"></page-header> -->
      <!-- <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :columns="columns"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :table-key="tableKey"
        :scroll="{ x: 0 }"
        :hide-row-selection="true"
        :is-full="true"
        :current-page="pageNumber"
        @emitRefreshData="getData"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="true"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        @emitRefreshData="getData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row type="flex" align="middle">
          <!-- 搜索 -->
          <a-col :span="6">
            <a-input-search
              v-if="isGranted('Pages.AgreementCategory.Query')"
              name="filterText"
              @search="getData"
              :placeholder="l('SearchWithThreeDot')"
              enterButton
              v-model.trim="filterText"
            />
          </a-col>
          <a-col :span="18" style="text-align: right">
            <a-button
              :type="'primary'"
              @click="createItem"
              v-if="isGranted('Pages.AgreementCategory.Create')"
            >
              <span>添加协议分类</span>
            </a-button>
          </a-col>
        </a-row>
      </ele-table>

      <a-modal
        centered
        :title="tableName"
        :visible="inputModalParams.visible"
        :confirm-loading="inputModalParams.confirmLoading"
        :cancelText="inputModalParams.cancelText"
        :okText="inputModalParams.okText"
        :maskClosable="inputModalParams.maskClosable"
        :destroyOnClose="inputModalParams.destroyOnClose"
        :width="inputModalParams.width"
        @ok="inputModalParams.confirm"
        @cancel="inputModalParams.cancel"
      >
        <div>
          <a-form-model
            :model="inputModalParams.form"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-model-item label="名称" required>
              <a-input
                v-model="inputModalParams.form.name"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="描述" required>
              <a-input
                v-model="inputModalParams.form.describe"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="排序" required>
              <a-input
                v-model="inputModalParams.form.sort"
                placeholder="请输入"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { AgreementCategoryServiceProxy } from "./services/agreement-category-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
// import { ListTable } from "@/components";
import EleTable from "@/components/ele-table";

export default {
  mixins: [AppComponentBase],
  name: "agreement-category-fields",
  components: {
    EleTable,
  },
  data() {
    return {
      agreementCategoryService: null,
      tableKey: "GetAgreementCategoryPagedDto",
      tableName: "协议分类",
      tableServiceName: "",
      name: "",
      // 表格
      spinning: false,
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      // 表格
      // columns: [],
      columns: [
        {
          title: this.l("分类名称"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("描述"),
          dataIndex: "describe",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "describe" },
        },
        {
          title: this.l("排序"),
          dataIndex: "sort",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      // list-table btns显示参数
      actionsType: {},
      modalShow: false,
      // modal参数
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        cancelText: "取消",
        okText: "确认",
        form: {
          id: "",
          name: "",
          sort: "",
          describe: "",
        },
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  created() {
    this.agreementCategoryService = new AgreementCategoryServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.getData();
    this.initActionsType();
  },
  methods: {
    // 获取数据
    async getData() {
      this.spinning = true;
      try {
        let res = await this.agreementCategoryService.getPaged(
          this.name,
          this.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        );
        this.spinning = false;
        this.tableData = res.items;
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
      } catch (error) {
        this.spinning = false;
      }
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "AgreementTemplete",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.AgreementCategory.Edit"),
              name: this.l("修改"),
              icon: "profile",
              fn: (data) => {
                _this.clearForm();
                _this.inputModalParams.form = { ...data };
                _this._save();
                _this.inputModalParams.visible = true;
              },
            },
          ],
          delete: {
            granted: this.isGranted("Pages.AgreementCategory.Delete"),
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // 重置
    refreshGoFirstPage() {
      this.filterText = "";
      this.request.skipCount = 0;
      this.getData();
    },
    // 创建
    createItem() {
      // let { item } = newV;
      // console.log(item);
      this.clearForm();
      this._save();
      this.inputModalParams.visible = true;
    },
    // 增改保存
    _save() {
      let _this = this;
      this.inputModalParams.title = this.tableName;
      this.inputModalParams.confirm = async function () {
        if (
          _this.inputModalParams.form.name === "" ||
          _this.inputModalParams.form.name === null
        ) {
          _this.$message.info("名称不能为空");
          return;
        } else if (
          _this.inputModalParams.form.name === "" ||
          _this.inputModalParams.form.name === null
        ) {
          _this.$message.info("排序不能为空");
          return;
        }
        _this.spinning = true;
        try {
          let res = await _this.agreementCategoryService.save({
            id: _this.inputModalParams.form.id,
            name: _this.inputModalParams.form.name,
            describe: _this.inputModalParams.form.describe,
            sort: _this.inputModalParams.form.sort,
          });
          _this.spinning = false;
          _this.inputModalParams.visible = false;
          _this.getData();
          _this.notify.success(_this.l("SavedSuccessfully"));
        } catch (error) {
          _this.spinning = false;
        }
      };
    },
    // 单个删除
    async deleteItem(id) {
      // let { id } = newV.item;
      this.spinning = true;
      try {
        let res = await this.agreementCategoryService.delete(id);
        this.spinning = false;
        this.inputModalParams.visible = false;
        this.getData();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
      } catch (error) {
        this.spinning = false;
      }
    },
    // 清空增改form
    clearForm() {
      this.inputModalParams.form.id = this.inputModalParams.form.name = this.inputModalParams.form.describe = this.inputModalParams.form.sort =
        "";
    },
  },
};
</script>

<style scoped lang="less">
.agreement-category-fields {
}
</style>
