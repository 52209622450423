import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { CommonProcess } from "@/shared/common-process";

const cp = new CommonProcess()

import {
  GeneralFieldsOfAgreementContentGroupGetPagedDTO,
  AgreementCategoryDTO,
  IAgreementCategoryDTO
} from './model'

import {SwaggerException} from "@/shared/service-proxies/model";

import moment from 'moment';

export class AgreementCategoryServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 合同分类管理---分页
   * @param serviceType
   * @param name 
   * @param filterText 
   * @param sorting 
   * @param maxResultCount 
   * @param skipCount 
   */
  getPaged(name: string, filterText: string, sorting: string, maxResultCount: number, skipCount: number): Promise<GeneralFieldsOfAgreementContentGroupGetPagedDTO> {
    let url_ = this.baseUrl + `/api/services/app/AgreementCategory/GetPaged?name=${name}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 读取select数据
   */
  getSelectData(): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementCategory/GetSelectData`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同分类管理---增改
   * @param body 
   */
  save(body: IAgreementCategoryDTO | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementCategory/Save`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同分类管理---删除
   * @param id 
   */
  delete(id: number | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/AgreementCategory/Delete?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

}


function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}
